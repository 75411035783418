<template>
  <div class="main_wrap">
    <h1>{{$t('write_judge')}}</h1>
    <div class="modal_content">
      <div class="modal_wrap">
        <div class="content1">
          <h3>{{$t('write_point')}}</h3>
          <div class="score_write_wrap">
            <input type="number" :min="0" :max="100" :value="parseInt(score)" @input="parentChange('score',$event.target.value)" >
            <div class="max_score">
              <span>/100{{$t('jum')}}</span>
            </div>
          </div>
        </div>
        <div class="content2">
          <h3>{{$t('reason_write_point')}}</h3>
          <div class="reason_write_wrap">
            <textarea :value="reason" @input="parentChange('reason',$event.target.value)"></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "JudgeWriteDefaultLayout",
  mixins: [],
  components: {
  },
  inject: ['JudgeSetData'],
  provide() {
    return{

    }
  },
  props: {
  },
  data() {
    return{
        score : 0,
        reason: ''
    }
  },
  beforeRouterEnter() {},
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{
      parentChange(type, value) {
          this[type] = value;
          this.JudgeSetData(type, value);
      },
  },
  watch: {
      'score': {
          deep: true,
          handler: function (val, oldVal) {
              let x = this.score

              if (Number(x) > 100) {
                  this.parentChange('score','100')
              }
          }
      }
  },
}
</script>

<style scoped>

</style>
